import React from 'react';
import { Link } from 'gatsby';
import menuStyle from './menu.module.scss';

type MenuProps = {
  items: MenuItemsProps[];
  className?: string;
};

type MenuItemsProps = {
  url: string;
  label: string;
  external?: boolean;
};

const Menu: React.FunctionComponent<MenuProps> = ({
  items,
  className,
  ...props
}) => {
  // Add all classes to an array
  const addAllClasses = ['menu'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <div className={menuStyle.menuWrapper} {...props}>
      <ul className={`${menuStyle.menuItemWrapper} ${addAllClasses.join(' ')}`}>
        {items.map((item, index) => (
          <li className={menuStyle.menuItem} key={index}>
            {item.external ? (
              <a href={item.url}>{item.label}</a>
            ) : (
              <Link to={item.url} activeClassName={menuStyle.activeLink}>
                {item.label}
              </Link>
            )}
          </li>
        ))}
      </ul>

    </div>
  );
};

export default Menu;
