import React, { useState } from 'react';
import { IoIosSearch, IoIosClose } from 'react-icons/io';
import { DrawerProvider } from '../drawer/drawer-context';
import Menu from './menu/menu';
import MobileMenu from './menu/mobile-menu';
import SearchContainer from '../../containers/search/search';
import navbarStyle from './navbar.module.scss';
import LogoImage from '../../images/logo.png';

type NavbarProps = {
  className?: string;
};

const MenuItems = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Projects',
    url: '/projects',
  },
  {
    label: 'Map',
    url: '/map',
  },
  {
    label: 'Blogs',
    url: '/blogs',
  },
  {
    label: 'Photography',
    url: '/photography',
  }
];

const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const [state, setState] = useState({
    toggle: false,
    search: '',
  });

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };

  // Add all classes to an array
  const addAllClasses = ['header'];

  // className prop checking
  if (className) {
    addAllClasses.push(navbarStyle.headerWrapper);
    addAllClasses.push(className);
  }

  return (
    <div className={addAllClasses.join(' ')} {...props}>
      <div className={navbarStyle.navbarWrapper}>
        <DrawerProvider>
          <MobileMenu items={MenuItems} logo={LogoImage} />
        </DrawerProvider>

        <Menu items={MenuItems} />

        <button
          className={navbarStyle.navSearchButton}
          type="button"
          aria-label="search"
          onClick={toggleHandle}
        >
          <IoIosSearch size="23px" />
        </button>
      </div>

      <div className={`${navbarStyle.navSearchWrapper} ${state.toggle ? navbarStyle.expand : ''}`}>
        <div className={navbarStyle.navSearchFromWrapper}>
          <SearchContainer />
          <button
            className={navbarStyle.searchCloseButton}
            type="submit"
            aria-label="close"
            onClick={toggleHandle}
          >
            <IoIosClose />
          </button>
        </div>
      </div>

    </div>
  );
};

export default Navbar;
